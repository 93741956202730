import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Subpage } from '../../interfaces';
import { encodeUri } from '../../utils/EncodeUri';
import { theme } from '../../utils/Theme';
import { Button } from '../Button';

const Container = styled.div``;
const ButtonWrapper = styled.div`
  width: max-content;
  margin: 25px auto 0 auto;
  ${theme.mediaQuerySizes.tablet} {
    margin-bottom: 60px;
  }
`;

const ContactBoxItem = styled.div`
  border-top: 3px ${theme.colors.green} solid;
  border-bottom: 3px ${theme.colors.green} solid;
  border-left: 1px ${theme.colors.light} solid;
  border-right: 1px ${theme.colors.light} solid;
  text-align: center;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 1.8rem;
  color: ${theme.colors.green};
  margin-top: 25px;
`;
const Address = styled.div`
  margin: 25px 0 25px 0;
`;

interface ContactBoxProps {
  address: string;
  name: string;
}

const redirectToHomePage = (kindergartenName: string, homePath: string): string => {
  return '/' + encodeUri(kindergartenName) + '/' + homePath;
};

interface queryResponse {
  subpagesJson: Subpage;
}

const query = graphql`
  query {
    subpagesJson(key: { eq: "home" }) {
      url
    }
  }
`;

const ContactBox = ({ name, address }: ContactBoxProps) => {
  return (
    <StaticQuery
      query={query}
      render={(data: queryResponse) => {
        return (
          <Container>
            <ContactBoxItem>
              <Name>{name}</Name>
              <Address>{address}</Address>
            </ContactBoxItem>
            <ButtonWrapper>
              <Link to={redirectToHomePage(name, data.subpagesJson.url)}>
                <Button>Sprawdź przedszkole</Button>
              </Link>
            </ButtonWrapper>
          </Container>
        );
      }}
    />
  );
};

export default ContactBox;
