import React from 'react';

import ContactIncentive from '../../components/contact/ContactIncentive';
import GreenStripe from '../../components/GreenStripe';
import ContactForm from '../../components/home/ContactForm';
import { ContactInfo } from '../../components/home/ContactInfo';
import { Map } from '../../components/home/map/Map';
import FacebookHome from '../../components/home/Socials';
import KindergartenContactSection from '../../components/KindergartenContactSection';
import { Section } from '../../components/Section';
import { SEO } from '../../components/Seo';
import { PageProps } from '../../interfaces/PageProps';
import { decodeUri } from '../../utils/DecodeUri';
import { getKindergartenByPathname } from '../../utils/GetKindergartenByPathname';
import { theme } from '../../utils/Theme';

const Contact = (props: PageProps) => (
  <>
    <SEO title={decodeUri(getKindergartenByPathname(props.location.pathname))} />
    <GreenStripe />
    <Section backgroundColor={theme.colors.white}>
      <ContactIncentive location={props.location} />
    </Section>
    <ContactInfo location={props.location}></ContactInfo>
    <Section backgroundColor={theme.colors.white}>
      <ContactForm></ContactForm>
      <FacebookHome location={props.location}></FacebookHome>
    </Section>
    <Section backgroundColor={theme.colors.creamy}>
      <KindergartenContactSection></KindergartenContactSection>
    </Section>
    <Map location={props.location}></Map>
  </>
);

export default Contact;
