import React from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

import { ChildImageSharp } from '../../interfaces';
import { AllMarkdownRemarkQueryResult } from '../../interfaces/AllMarkdownRemark';
import { Location } from '../../interfaces/Location';
import { MarkdownFileNode } from '../../interfaces/MarkdownFileNode';
import { allMarkdownRemark } from '../../queries/AllMarkdownRemark';
import { Container, Description } from '../../styles/CommonStyledComponents';
import { findMarkdownFile } from '../../utils/FindMarkdownFile';
import { theme } from '../../utils/Theme';
import Headline from '../headline/Headline';
import { Image } from '../Image';

const SectionInfo = styled.div`
  display: flex;
  ${theme.mediaQuerySizes.tablet} {
    display: block;
  }
`;

interface ContactPageNode extends MarkdownFileNode {
  frontmatter: {
    image: ChildImageSharp;
    title: string;
  };
}

const ContactIncentive = (props: Location) => {
  const data: AllMarkdownRemarkQueryResult<MarkdownFileNode> | undefined = allMarkdownRemark<
    AllMarkdownRemarkQueryResult<MarkdownFileNode>
  >();
  const markdownFile = findMarkdownFile(data!.allMarkdownRemark, props.location.pathname, 'contact-page');
  return (
    <Container>
      <Headline arrowColor="arrowGreen" title={markdownFile?.frontmatter?.title || ''}></Headline>
      <SectionInfo>
        <Image
          loading="eager"
          fadeIn={false}
          floated={false}
          fluid={markdownFile?.frontmatter?.image?.childImageSharp.fluid}
        />
        <Description
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(markdownFile?.html || ''),
          }}
        ></Description>
      </SectionInfo>
    </Container>
  );
};

export default ContactIncentive;
