import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { KindergartensOrder } from '../interfaces/KindergartensOrder';
import { findKindergartenByAllKindergertensJson } from '../utils/FindKindergartenByAllKindergartensJson';
import { theme } from '../utils/Theme';
import ContactBox from './contact/ContactBox';
import Headline from './headline/Headline';

const ContactAddressBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin: 0 auto;
  width: 70%;
  ${theme.mediaQuerySizes.laptop} {
    width: 90%;
  }
  ${theme.mediaQuerySizes.tablet} {
    grid-template-columns: 1fr;
    width: 80%;
    margin-top: 50px;
  }
  ${theme.mediaQuerySizes.phoneLight} {
    width: 100%;
  }
`;

const CheckText = styled.div`
  text-align: center;
  margin: 35px 0;
  color: ${theme.colors.grey};
`;

interface Kindergarten {
  address: string;
  name: string;
}

const query = graphql`
  query {
    allKindergartensJson: allKindergartensJson {
      nodes {
        address
        name
      }
    }
    kindergartensOrder: markdownRemark(fileAbsolutePath: { regex: "/_data/settings/kindergartens-order.md/" }) {
      frontmatter {
        collection {
          collectionName
        }
      }
    }
  }
`;

interface queryResponse {
  allKindergartensJson: {
    nodes: Kindergarten[];
  };
  kindergartensOrder: KindergartensOrder;
}

let kindergarten;

const KindergartenContactSection = () => {
  return (
    <StaticQuery
      query={query}
      render={(data: queryResponse) => {
        return (
          <>
            <Headline arrowColor="arrowGreen" title="Nutka to aż trzy przedszkola"></Headline>
            <ContactAddressBoxes>
              {data.kindergartensOrder.frontmatter.collection.map((collection) => {
                kindergarten = findKindergartenByAllKindergertensJson(data, collection.collectionName);
                return (
                  <ContactBox
                    key={kindergarten?.name || ''}
                    name={kindergarten?.name || ''}
                    address={kindergarten?.address || ''}
                  ></ContactBox>
                );
              })}
            </ContactAddressBoxes>
            <CheckText>Sprawdź gdzie Twoje dziecko będzie miało najbliżej</CheckText>
          </>
        );
      }}
    />
  );
};

export default KindergartenContactSection;
